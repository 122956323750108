import { Controller } from "@hotwired/stimulus"
import "select2"

import client from "braintree-web/client"
import paypalCheckout from "braintree-web/paypal-checkout"
import applePay from "braintree-web/apple-pay"
import hostedFields from "braintree-web/hosted-fields"

const DARK_60 = "#9F9EA1" // colors.scss

// PayPal/ApplePay/CC-Braintree
export default class extends Controller {
  static values = {
    billingAgreement: String,
    availableStates: Array,
    styleOption: String
  }

  connect () {
    this.runConnect()
  }

  disconnect () {
    $("#customer_form").off("change")
    $("#customer_form").off("keyup")
  }

  runConnect() {
    const billingAgreement = this.billingAgreementValue
    const availableStates = this.availableStatesValue
    const styleOption = this.styleOptionValue

    if (window.ApplePaySession &&
      window.ApplePaySession.supportsVersion(3) &&
      window.ApplePaySession.canMakePayments()) {
      $("#apple-pay-button-main-container").show()
    }

    const initializeHostedFieldsCallback = function (hostedFieldsErr, hostedFieldsInstance) {
      if (hostedFieldsErr) {
        console.error(hostedFieldsErr)
        return
      }

      function isPaypal() {
        return $("#payment_method_type").val() === "paypal"
      }

      function isApplePay() {
        return $("#payment_method_type").val() === "apple_pay"
      }

      function doNotValidateCC() {
        return isPaypal() || isApplePay()
      }

      // returns true if all hosted fields are valid
      function ccAllValid() {
        if (doNotValidateCC()) {
          return true
        }

        const state = hostedFieldsInstance.getState()

        let allValid = true

        for (const key in state.fields) {
          const field = state.fields[key]
          const isValid = field.isValid
          const fieldGroup = $(field.container).parents(".form-group")
          if (!isValid) {
            allValid = false
          }
          ccUpdateValidityStyling(fieldGroup, key, isValid)
        }

        return allValid
      }

      // Update styling for hosted fields
      function ccUpdateValidityStyling(fieldGroup, fieldName, isValid) {
        const numberField = $("#cc-number")
        const expField = $("#cc-exp")
        const cvvField = $("#cc-cvv")

        // console.log("ccUpdateValidityStyling " + fieldName + " " + isValid)

        if (isValid) {
          if (fieldName === "number") {
            numberField.next("span").text("")
            numberField.addClass("is-valid")
            numberField.removeClass("is-invalid")
          } else if (fieldName === "expirationDate") {
            expField.next("span").text("")
            expField.addClass("is-valid")
            expField.removeClass("is-invalid")
          } else if (fieldName === "cvv") {
            cvvField.next("span").text("")
            cvvField.addClass("is-valid")
            cvvField.removeClass("is-invalid")
          }
          fieldGroup.addClass("fv-has-success")
          fieldGroup.removeClass("fv-has-error")
        } else {
          // Potentially valid we process like errors
          fieldGroup.addClass("fv-has-error")
          fieldGroup.removeClass("fv-has-success")
          if (fieldName === "number") {
            numberField.next("span").text("Credit Card Number is not valid")
            numberField.addClass("is-invalid")
            numberField.removeClass("is-valid")
          } else if (fieldName === "expirationDate") {
            expField.next("span").text("Expiration date is not valid")
            expField.addClass("is-invalid")
            expField.removeClass("is-valid")
          } else if (fieldName === "cvv") {
            cvvField.next("span").text("CVV is not valid")
            cvvField.addClass("is-invalid")
            cvvField.removeClass("is-valid")
          }
        }
      }

      function writeNonce() {
        hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
          if (tokenizeErr) {
            console.error(tokenizeErr)
            disableCCSubmitButton()
            scrollToPayment()
            return
          } else {
            enableCCSubmitButton()
          }

          $("#payment_method_type").val("credit_card")
          $("#payment_method_nonce").val(payload.nonce)
          // console.log("wrote nonce!")
        })
      }

      hostedFieldsInstance.on("validityChange", function (event) {
        // console.log("validityChange")
        if (doNotValidateCC()) {
          return
        }

        const fieldName = event.emittedBy
        const field = event.fields[fieldName]
        const isValid = field.isValid
        const fieldGroup = $(field.container).parents(".form-group")
        if (ccAllValid()) {
          writeNonce()
        } else {
          disableCCSubmitButton()
        }

        ccUpdateValidityStyling(fieldGroup, fieldName, isValid)
      })

      function formChangeHandler() {
        if (!ccAllValid()) {
          disableCCSubmitButton()
        }
      }

      if ($("#payment-info").length) {
        $("#customer_form").on("change", ":input", formChangeHandler)
        $("#customer_form").on("keyup", ":input", formChangeHandler)
      }

      // Hiding Braintree iframes to skip them in screen readers
      for (const field in hostedFieldsInstance._fields) {
        const fieldFrame = hostedFieldsInstance._fields[field].frameElement
        $(fieldFrame).attr("aria-hidden", true)
      }
    }

    const createClientCallback = function (clientErr, clientInstance) {
      if (clientErr) {
        console.error(clientErr)
        alert("Failed to initialize Braintree Client")
        return
      }

      if ($("#cc-number").length) {
        creditCardCallback(clientInstance, getBraintreeStyles())
      }
      if ($("#paypal-button").length) {
        paypalCallback(clientInstance, getPaypalButtonStyle())
      }

      if ($("#apple-pay-button").length) {
        applePayCallback(clientInstance)
      }
    }

    const creditCardCallback = function (clientInstance, styles) {
      const options = {
        client: clientInstance,
        styles: styles,
        fields: {
          number: {selector: "#cc-number", placeholder: "Credit Card Number"},
          cvv: {selector: "#cc-cvv", placeholder: "CVV"},
          expirationDate: {selector: "#cc-exp", placeholder: "MM/YY"}
        }
      }

      hostedFields.create(options, initializeHostedFieldsCallback)
    }

    const restoreDefaultCheckout = function(messageType = "error") {
      $("#basics-form").show()
      $("#style-quiz-buttons").show()
    }

    const hideDefaultCheckout = function() {
      $("#basics-form").hide()
      $("#style-quiz-buttons").hide()
    }

    const paypalCallback = function (clientInstance, style) {
      paypalCheckout.create({
        client: clientInstance,
      }, function (paypalCheckoutErr, paypalCheckoutInstance) {
        // console.log("paypalCallback loadPayPalSDK")
        paypalCheckoutInstance.loadPayPalSDK({
          vault: true
        }, function () {
          window.paypal.Buttons({
            style: style,

            fundingSource: window.paypal.FUNDING.PAYPAL,

            createBillingAgreement: function () {
              return paypalCheckoutInstance.createPayment({
                // https://developer.paypal.com/braintree/docs/guides/paypal/vault
                flow: "vault",

                // The following are optional params
                billingAgreementDescription: billingAgreement,
                enableShippingAddress: false,
                shippingAddressEditable: false,
                enableBillingAddress: true,
              })
            },

            onClick: function () {
              disableCCSubmitButton()
              hideBillingCheckboxSection()
              $("#paypal-billing-adddress-error").hide()
            },

            onApprove: function (data, actions) {
              return paypalCheckoutInstance.tokenizePayment(data, function (err, payload) {
                // Even if the billing address is not available in prod, don't fail the checkout
                if (payload.details.billingAddress) {
                  const { city, line1, postalCode, state } = payload.details.billingAddress
                  $("#web_signup_billing_address_street").val(line1)
                  $("#web_signup_billing_address_city").val(city)
                  $("#web_signup_billing_address_zip").val(postalCode)
                  const stateId = getStateId(state)
                  if (!stateId) {
                    console.error("Paypal billing address not supported")
                    $("#paypal-billing-adddress-error").show().html("Paypal billing address not supported")
                    return
                  }
                  $("#web_signup_billing_address_state_id").val(stateId)
                  $("#billing-same-as").prop("checked", false)
                }
                $("#payment_method_type").val("paypal")
                $("#payment_method_nonce").val(payload.nonce)

                const element = document.getElementById("paypal-button")
                element.innerHTML = "<h3>Submitting ...</h3>"

                // const form = $("#customer_form")
                // form.submit()
                document.getElementById("customer_form").requestSubmit()
              })
            },

            onCancel: function (data) {
              enableCCSubmitButton()
              showBillingCheckboxSection()
            },

            onError: function (err) {
              enableCCSubmitButton()
              showBillingCheckboxSection()
            }
          }).render("#paypal-button").then(function () {
            const element = document.getElementById("paypal-button-loader")
            element.style.display = "none"

            const paypalButton = document.getElementById("paypal-button")
            paypalButton.style.display = "block"
          })
        })
      })
    }

    const applePayCallback = function (clientInstance) {
      if (!(window.ApplePaySession && window.ApplePaySession.canMakePayments())) {
        return
      }

      applePay.create({
        client: clientInstance
      }, function (applePayErr, applePayInstance) {
        if (applePayErr) {
          console.error("Error creating applePayInstance:", applePayErr)
          return
        }

        const loader = document.getElementById("apple-pay-button-loader")
        loader.style.display = "none"

        const applePayButton = document.getElementById("apple-pay-button")
        applePayButton.style.display = "block"

        $("#apple-pay-button").click(() => onApplePayButtonClicked(applePayInstance))
      })
    }

    function onApplePayButtonClicked(applePayInstance) {
      disableCCSubmitButton()

      /*
        This comes from a hidden field, but we do not need to be defensive
        against manipulation because this quantity is only used for display
        purposes, no charge is performed.
      */
      const totalDue = $("#total_due").val() || "0.00"

      const request = {
        countryCode: "US",
        currencyCode: "USD",
        merchantCapabilities: [
          "supports3DS",
          "supportsDebit",
          "supportsCredit"
        ],
        supportedNetworks : [
          "visa",
          "masterCard",
          "amex",
          "discover",
          "jcb"
        ],
        requiredBillingContactFields: [
          "postalAddress"
        ],
        "lineItems": [],
        "recurringPaymentRequest": {
          paymentDescription: billingAgreement,
          billingAgreement: billingAgreement,
          managementURL: "https://nadinewest.com/profile",
          regularBilling: {
            label: "Recurring Payment",
            amount: "0.00",
            paymentTiming: "recurring",
            type: "pending",
            recurringPaymentStartDate: new Date().toString()
          }
        },
        total: {
          label: "Nadine West",
          amount: totalDue
        }
      }

      // Create ApplePaySession
      const session = new window.ApplePaySession(3, request)

      session.onvalidatemerchant = (event) => {
        applePayInstance.performValidation({
          validationURL: event.validationURL,
          displayName: "Nadine West Store"
        }, function (validationErr, validationData) {
          if (validationErr) {
            console.error(validationErr)
            session.abort()
            return
          }

          session.completeMerchantValidation(validationData)
        })
      }

      session.onpaymentauthorized = event => {
        applePayInstance.tokenize({
          token: event.payment.token
        }, function (tokenizeErr, tokenizedPayload) {
          if (tokenizeErr) {
            session.completePayment(window.ApplePaySession.STATUS_FAILURE)
            return
          }

          // Send the tokenizedPayload to your server here!
          $("#payment_method_type").val("apple_pay")
          $("#payment_method_nonce").val(tokenizedPayload.nonce)

          // Once the transaction is complete, call completePayment
          // to close the Apple Pay sheet
          session.completePayment(window.ApplePaySession.STATUS_SUCCESS)

          const applePayButton = document.getElementById("apple-pay-button")
          applePayButton.style.display = "none"

          const loader = document.getElementById("apple-pay-button-loader")
          loader.innerHTML = "<h3>Submitting ...</h3>"
          loader.style.display = "block"

          document.getElementById("customer_form").requestSubmit()
        })
      }

      session.oncancel = event => {
        enableCCSubmitButton()
      }

      session.begin()
    }

    function enableCCSubmitButton () {
      // console.log("enableCCSubmitButton")
      const submitButton = $("#register_btn")
      submitButton.removeAttr("disabled")
    }

    function disableCCSubmitButton () {
      // console.log("disableCCSubmitButton")
      const submitButton = $("#register_btn")
      submitButton.attr("disabled", "disabled")
    }

    function hideBillingCheckboxSection () {
      const billingSection = document.getElementById("billing-same-as-section")
      billingSection ? billingSection.style.display = "none" : null
    }

    function showBillingCheckboxSection () {
      const billingSection = document.getElementById("billing-same-as-section")
      billingSection ? billingSection.style.display = "block" : null
    }

    function getStateId(state) {
      const stateFound = availableStates.find((availableState) => availableState.code === state)
      return stateFound?.id
    }

    // scrolling to payment
    function scrollToPayment() {
      $("html, body").animate({scrollTop: $("#payment-info").offset().top}, 0)
    }

    // if braintree error is rendered, scrolling to error message
    if ($(".braintree-error-message").length) {
      scrollToPayment()
    }

    if ($("#payment-info").length || $("#shipping-info").length) {
      $.get(
        "/api/braintree_token",
        {},
        function(token, status, xhr) {
          client.create({authorization: token}, createClientCallback)
        },
        "text"
      )
    }

    function getPaypalButtonStyle() {
      const styles = {
        default: { disableMaxWidth: true },
        feelGreat: { disableMaxWidth: true, shape: "pill" },
        selfCheckout: { shape: "pill", height: 55 }
      }

      return styles[styleOption] || styles.default
    }

    function getBraintreeStyles() {
      const placeholderColor = styleOption == "feelGreat" || styleOption == "selfCheckout" ? DARK_60 : "#7D7D7D"
      const styles = {
        ".invalid": {"color": "#a94442"},
        ".valid": {"color": "green"},
        "input": {
          "font-size": "16px"
        },
        ":focus": {
          "color": "black"
        },
        "::-webkit-input-placeholder": {
          "color": placeholderColor
        },
        ":-moz-placeholder": {
          "color": placeholderColor
        },
        "::-moz-placeholder": {
          "color": placeholderColor
        },
        ":-ms-input-placeholder": {
          "color": placeholderColor
        }
      }

      return styles
    }
  }
}
