import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sessionInput"]
  static values = {
    env: String,
    merchantId: String
  }

  connect() {
    this.initFinixSession()
  }

  initFinixSession() {
    // Set session ID to all session input targets
    this.sessionInputTargets.forEach(input => {
      window.Finix.Auth(this.envValue, this.merchantIdValue, (sessionKey) => {
        input.value = sessionKey
      })
    })
  }
}